import {Component, Input, OnInit} from '@angular/core';
import {PressroomAccueilSection} from '../../../../Model/PressroomAccueilSection';

@Component({
  selector: 'app-pressroom-accueil-text-section',
  templateUrl: './pressroom-accueil-text-section.component.html',
  styleUrls: ['./pressroom-accueil-text-section.component.scss']
})
export class PressroomAccueilTextSectionComponent implements OnInit {

  @Input()
  section: PressroomAccueilSection;

  position;

  constructor() { }

  ngOnInit() {
    this.initPosition();
  }

  initPosition() {
    if ( this.section.contentDisposition === 'g') {
      this.position = 'left';
    } else if ( this.section.contentDisposition === 'd') {
      this.position = 'right';
    } else if ( this.section.contentDisposition === 'j') {
      this.position = 'justify';
    } else {
      this.position = 'center';
    }

  }
}

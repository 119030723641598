import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from '../../../Service/Share/PressroomShareService';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-pressroom-home',
  templateUrl: './pressroom-home.component.html',
  styleUrls: ['./pressroom-home.component.scss']
})
export class PressroomHomeComponent implements OnInit {

  constructor(public pressroomShareService: PressroomShareService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.pressroomShareService.setOnglet('accueil');
  }
*

  ongletActif(onglet) {
      if (this.pressroomShareService.getOngletLoad() === onglet) {
        return 'blockTitre';
      } else {
        return 'keyword';
      }
    }
}

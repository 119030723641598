import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from '../../../Service/Share/PressroomShareService';
import {PressroomAccueilSection} from '../../../Model/PressroomAccueilSection';
import {ImagePressroom} from '../../../Model/ImagePressroom';
import {Environement} from '../../../environement/environement';

@Component({
  selector: 'app-pressroom-accueil',
  templateUrl: './pressroom-accueil.component.html',
  styleUrls: ['./pressroom-accueil.component.scss']
})
export class PressroomAccueilComponent implements OnInit {

  constructor(public pressroomShareService: PressroomShareService) { }

  ngOnInit() {
  }

  styleTitle(){
    let retour = {
      color: undefined
    };
    retour.color = this.pressroomShareService.pressroom.pressroomAccueil.couleurTitle;
    return retour;
  }
  styleTitleSection(section: PressroomAccueilSection){
    let retour = {
      color: undefined
    };
    retour.color = section.couleurTitle;
    return retour;
  }
  isTitleOk(section: PressroomAccueilSection){
    return section.title.length > 3;
  }

  getImageSource(logo: ImagePressroom){
    return Environement.BASE_API_URL + '/image/pressroom/content/' + logo.id;
  }
}

import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from '../../../Service/Share/PressroomShareService';
import {Environement} from '../../../environement/environement';
import {PressroomEvenement} from '../../../Model/PressroomEvenement';

@Component({
  selector: 'app-pressroom-evenement',
  templateUrl: './pressroom-evenement.component.html',
  styleUrls: ['./pressroom-evenement.component.scss']
})
export class PressroomEvenementComponent implements OnInit {
  anneeEvenement: Array<string>;
  anneeChoisi: string;

  constructor(public pressroomShareService: PressroomShareService) {
  }

  ngOnInit() {
    this.initAnneeCommunique();
  }

  initAnneeCommunique() {
    const that = this;
    this.pressroomShareService.setOnglet('evenement');
    setTimeout(() => {
      that.pressroomShareService.pressroom.pressroomEvenement.forEach((evenement) => {
        const date = new Date(evenement.date);
        if (!that.anneeEvenement.includes(date.getFullYear().toString())) {
          that.anneeEvenement.unshift(date.getFullYear().toString());
        }
      });
    }, 1000);
  }
  afficheCommunique(evenement: PressroomEvenement) {
    if (this.anneeChoisi === 'all') {
      return true;
    } else {
      const date = new Date(evenement.date);
      return this.anneeChoisi === date.getFullYear().toString();
    }
  }

  getTitle() {
    if (this.anneeChoisi === 'all') {
      return 'Événements';
    } else {
      if (this.anneeChoisi) {
      return 'Événements ' + this.anneeChoisi;
      } else {
        return 'Événements';
      }
    }
  }

  getUrlImage(evenement: PressroomEvenement) {
    return Environement.BASE_API_URL + '/evenement/' + evenement.id + '.' + evenement.photo;
  }

  getCpLink(evenement: PressroomEvenement) {
    return Environement.BASE_API_URL + '/evenement/' + evenement.id + '.' + evenement.pdf;
  }
}

import {Injectable} from '@angular/core';
import {Pressroom} from '../../Model/Pressroom';
import {PressroomService} from '../App/PressroomService';

@Injectable()
export class PressroomShareService {

  pressroom: Pressroom;
  affichageArticle: string;
  pressroomService: PressroomService;

  private ongletLoad = '';

  constructor() {
  }

  public getOngletLoad() {
    return this.ongletLoad;
  }


  public setOnglet(ongletLoad: string) {
    this.ongletLoad = ongletLoad;
  }

  orderArticle() {
    this.pressroom.pressroomArticle.sort(function(a, b): number {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    });
  }

  loadRestePressroom(){
    this.loadLogo();
    this.loadCommuniques();
    this.loadPhototech();
    this.loadArticle();
    this.loadEvenement();
  }

  public orderCommunique() {
    this.pressroom.pressroomCommunique = this.pressroom.pressroomCommunique.reverse();
  }

  public loadLogo() {
    this.pressroomService.getAllLogoPressroom(this.pressroom.id)
      .then((retour) =>{
        this.pressroom.logos = retour;
      });
  }
  public loadPhototech() {
    const that = this;
    this.pressroomService.getAllPhototechPressroom(this.pressroom.id)
      .then((retour) =>{
        that.pressroom.phototech = retour;
      });
  }
  public loadCommuniques() {
    const that = this;
    this.pressroomService.getAllCommuniquePressroom(this.pressroom.id)
      .then((retour) =>{
        that.pressroom.pressroomCommunique = retour;
        that.orderCommunique();
      });
  }
  public loadArticle(){
    const that = this;
    this.pressroomService.getAllArticlePressroom(this.pressroom.id)
      .then((retour) =>{
        that.pressroom.pressroomArticle = retour.reverse();
        this.orderArticle();
      });
  }
  public loadEvenement(){
    const that = this;
    this.pressroomService.getAllEvenementPressroom(this.pressroom.id)
      .then((retour) =>{
        that.pressroom.pressroomEvenement = retour.reverse();
      });
  }

}

import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from '../../../../Service/Share/PressroomShareService';
import {PressroomArticle} from '../../../../Model/PressroomArticle';

@Component({
  selector: 'app-time-line-content',
  templateUrl: './time-line-content.component.html',
  styleUrls: ['./time-line-content.component.scss']
})
export class TimeLineContentComponent implements OnInit {
  constructor(public pressroomShareService: PressroomShareService) { }

  ngOnInit() {

  }

  isAffiche(article: PressroomArticle){
    if (this.pressroomShareService.affichageArticle === 'all'){
      return true;
    }
    const date = new Date(article.date);
    if (date.getFullYear().toString() + (date.getMonth() + 1) === this.pressroomShareService.affichageArticle){
      return true;
    }
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from "../../../Service/Share/PressroomShareService";
import {GetMoiService} from '../../../Service/Share/GetMoiService';

@Component({
  selector: 'app-pressroom-retombe',
  templateUrl: './pressroom-retombe.component.html',
  styleUrls: ['./pressroom-retombe.component.scss']
})
export class PressroomRetombeComponent implements OnInit {

  constructor(public pressroomShareService: PressroomShareService, public getMoiService: GetMoiService) { }

  ngOnInit() {
    this.pressroomShareService.setOnglet('retombe');
  }
  getTitle() {
    if (this.pressroomShareService.affichageArticle === 'all'){
      return 'Tous les articles';
    } else {
      const moi = this.getMoiService.getMoi(this.pressroomShareService.affichageArticle.substr(4,
        this.pressroomShareService.affichageArticle.length));
      return 'Articles du moi de ' +  moi + ' ' + this.pressroomShareService.affichageArticle.substr(0, 4);
    }
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {ImagePressroom} from '../../../../Model/ImagePressroom';
import {Environement} from '../../../../environement/environement';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-pressroom-outil-affichage',
  templateUrl: './pressroom-outil-affichage.component.html',
  styleUrls: ['./pressroom-outil-affichage.component.scss']
})
export class PressroomOutilAffichageComponent implements OnInit {

  @Input()
  images: Array<ImagePressroom>;

  constructor() { }

  ngOnInit() {
  }

  getImageSource(image: ImagePressroom){
    return Environement.BASE_API_URL + '/image/pressroom/content/' + image.id;
  }
  getImageDownload(image: ImagePressroom){
    return Environement.BASE_API_URL + '/image/pressroom/download/' + image.id + '.png';
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PressroomClientComponent } from './component/pressroom/pressroom-client/pressroom-client.component';
import {PressroomShareService} from './Service/Share/PressroomShareService';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PressroomHomeComponent } from './component/pressroom/pressroom-home/pressroom-home.component';
import { PressroomAccueilComponent } from './component/pressroom/pressroom-accueil/pressroom-accueil.component';
import { PressroomComuniqueComponent } from './component/pressroom/pressroom-comunique/pressroom-comunique.component';
import { PressroomRetombeComponent } from './component/pressroom/pressroom-retombe/pressroom-retombe.component';
import { PressroomOutilComponent } from './component/pressroom/pressroom-outil/pressroom-outil.component';
import { TimeLineContentComponent } from './component/pressroom/pressroom-retombe/time-line-content/time-line-content.component';
import { PressroomAccueilTextSectionComponent } from './component/pressroom/pressroom-accueil/pressroom-accueil-text-section/pressroom-accueil-text-section.component';
import { PressroomRetombeChoixDateComponent } from './component/pressroom/pressroom-retombe/pressroom-retombe-choix-date/pressroom-retombe-choix-date.component';
import {GetMoiService} from './Service/Share/GetMoiService';
import { PressroomContactComponent } from './component/pressroom/pressroom-contact/pressroom-contact.component';
import { PressroomOutilAffichageComponent } from './component/pressroom/pressroom-outil/pressroom-outil-affichage/pressroom-outil-affichage.component';
import { PressroomEvenementComponent } from './component/pressroom/pressroom-evenement/pressroom-evenement.component';

@NgModule({
  declarations: [
    AppComponent,
    PressroomClientComponent,
    PressroomHomeComponent,
    PressroomAccueilComponent,
    PressroomComuniqueComponent,
    PressroomRetombeComponent,
    PressroomOutilComponent,
    TimeLineContentComponent,
    PressroomAccueilTextSectionComponent,
    PressroomRetombeChoixDateComponent,
    PressroomContactComponent,
    PressroomOutilAffichageComponent,
    PressroomEvenementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [ PressroomShareService, GetMoiService],
  bootstrap: [AppComponent]
})
export class AppModule { }

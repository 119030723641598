import {Injectable} from '@angular/core';

@Injectable()
export class GetMoiService {

  getMoi(moi) {
    console.log(moi);
    if (moi === '1') {
      return 'Janvier';
    }
    if (moi === '2') {
      return 'Février';
    }
    if (moi === '3') {
      return 'Mars';
    }
    if (moi === '4') {
      return 'Avril';
    }
    if (moi === '5') {
      return 'Mai';
    }
    if (moi === '6') {
      return 'Juin';
    }
    if (moi === '7') {
      return 'Juillet';
    }
    if (moi === '8') {
      return 'Aout';
    }
    if (moi === '9') {
      return 'Septembre';
    }
    if (moi === '10') {
      return 'Octobre';
    }
    if (moi === '11') {
      return 'Novembre';
    }
    if (moi === '12') {
      return 'Décembre';
    }
    console.log(moi);
    return false;
  }

}

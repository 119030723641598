import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from '../../../../Service/Share/PressroomShareService';
import {GetMoiService} from '../../../../Service/Share/GetMoiService';

@Component({
  selector: 'app-pressroom-retombe-choix-date',
  templateUrl: './pressroom-retombe-choix-date.component.html',
  styleUrls: ['./pressroom-retombe-choix-date.component.scss']
})
export class PressroomRetombeChoixDateComponent implements OnInit {

  datePresent: Array<any>;
  openAnne: string;
  open:boolean;

  constructor(public pressroomShareService: PressroomShareService, public getMoiService: GetMoiService) { }

  ngOnInit() {
    this.datePresent = [];
    this.openAnne = 'all';
    this.open = true;
    this.pressroomShareService.affichageArticle = 'all';
    let lastArticle = this.pressroomShareService.pressroom.pressroomArticle[this.pressroomShareService.pressroom.pressroomArticle.length - 1];
    let minDate = lastArticle.date;
    minDate = new Date(minDate);
    while (minDate < new Date()) {
      this.datePresent.push(minDate.getFullYear().toString() + (minDate.getMonth() + 1));
      minDate.setMonth(minDate.getMonth() + 1);
    }
    this.datePresent = this.datePresent.reverse();
  }

  checkSameAnne(string1, string2 = 'a') {
    if (string2 === 'a') {
      return true;
    }
    if (string1.substr(0, 4) ===  string2.substr(0, 4)) {
      return false;
    }
    return true;
  }
  getAnnee(string){
    return string.substr(0, 4);
  }

  getMoiPart(moiAnne){
    return moiAnne.substr(4, moiAnne.length);
  }
}

import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from '../../../Service/Share/PressroomShareService';

@Component({
  selector: 'app-pressroom-contact',
  templateUrl: './pressroom-contact.component.html',
  styleUrls: ['./pressroom-contact.component.scss']
})
export class PressroomContactComponent implements OnInit {

  constructor(public pressroomShareService: PressroomShareService) { }

  ngOnInit() {
  }

}

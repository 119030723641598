import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PressroomClientComponent} from './component/pressroom/pressroom-client/pressroom-client.component';
import {PressroomHomeComponent} from './component/pressroom/pressroom-home/pressroom-home.component';
import {PressroomAccueilComponent} from './component/pressroom/pressroom-accueil/pressroom-accueil.component';
import {PressroomComuniqueComponent} from './component/pressroom/pressroom-comunique/pressroom-comunique.component';
import {PressroomRetombeComponent} from "./component/pressroom/pressroom-retombe/pressroom-retombe.component";
import {PressroomOutilComponent} from "./component/pressroom/pressroom-outil/pressroom-outil.component";
import {PressroomEvenementComponent} from './component/pressroom/pressroom-evenement/pressroom-evenement.component';

const routes: Routes = [
  {
    path: 'pressroom/:id',
    component: PressroomClientComponent,
    children : [
      {
        path: '',
        component: PressroomAccueilComponent
      },
      {
        path: 'communique',
        component: PressroomComuniqueComponent
      },
      {
        path: 'retombe',
        component: PressroomRetombeComponent
      },
      {
        path: 'outil',
        component: PressroomOutilComponent
      },
      {
        path: 'evenement',
        component: PressroomEvenementComponent
      }
      ]

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

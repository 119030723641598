import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PressroomShareService} from '../../../Service/Share/PressroomShareService';
import {PressroomService} from '../../../Service/App/PressroomService';
import {ImagePressroom} from '../../../Model/ImagePressroom';
import {Environement} from '../../../environement/environement';
import {log} from 'util';

@Component({
  selector: 'app-pressroom-client',
  templateUrl: './pressroom-client.component.html',
  styleUrls: ['./pressroom-client.component.scss'],
  providers: [ PressroomService ],
})
export class PressroomClientComponent implements OnInit {


  id: number;
  private sub: any;
  image:any;

  constructor(private route: ActivatedRoute,
              private pressroomService: PressroomService, public pressroomShareService: PressroomShareService) { }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      that.id = +params.id;
      this.pressroomShareService.pressroomService = this.pressroomService;
      that.pressroomService.getPressroom(that.id)
        .then( (pressroom) => {
          that.pressroomShareService.pressroom = pressroom;
          that.pressroomShareService.loadRestePressroom();
        });
    });
  }

  getImageSource(logo: ImagePressroom) {
    if (logo) {
      return Environement.BASE_API_URL + '/image/pressroom/content/' + logo.id;
    } else {
      return null;
    }
  }

}

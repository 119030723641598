import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from "../../../Service/Share/PressroomShareService";

@Component({
  selector: 'app-pressroom-outil',
  templateUrl: './pressroom-outil.component.html',
  styleUrls: ['./pressroom-outil.component.scss']
})
export class PressroomOutilComponent implements OnInit {

  constructor(public pressroomShareService: PressroomShareService) { }

  ngOnInit() {
    this.pressroomShareService.setOnglet('outil');
  }

}

import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../environement/environement';




@Injectable()
export class PressroomService {
  private pressroomUrl = Environement.BASE_API_URL + '/api/pressrooms';


  constructor(private http: HttpClient) {
  }

  getPressroom(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.pressroomUrl + '/' + id , { headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getAllLogoPressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/image/pressroom/logos/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  getAllPhototechPressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/image/pressroom/phototech/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAllCommuniquePressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/communique/pressroom/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAllArticlePressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/communique/pressroom/articles/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAllEvenementPressroom(idPressroom){
    const headers = this.initOptionHeader();
    return this.http.get( Environement.BASE_API_URL + '/evenement/pressroom/' + idPressroom,{ headers: headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }

}

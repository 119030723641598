import { Component, OnInit } from '@angular/core';
import {PressroomShareService} from '../../../Service/Share/PressroomShareService';
import {GetMoiService} from '../../../Service/Share/GetMoiService';
import {PressroomCommunique} from '../../../Model/PressroomCommunique';
import {Environement} from '../../../environement/environement';

@Component({
  selector: 'app-pressroom-comunique',
  templateUrl: './pressroom-comunique.component.html',
  styleUrls: ['./pressroom-comunique.component.scss'],
})
export class PressroomComuniqueComponent implements OnInit {

  anneeCommunique: Array<string>;
  anneeChoisi: string;


  constructor(public pressroomShareService: PressroomShareService, public getMoiService: GetMoiService) { }

  ngOnInit() {
    this.anneeCommunique = [];

    this.initAnneeCommunique();
    this.anneeChoisi = 'all';
  }

  initAnneeCommunique(){
    const that = this;
    this.pressroomShareService.setOnglet('communique');
    setTimeout(() => {
      that.pressroomShareService.pressroom.pressroomCommunique.forEach((communique) => {
      const date = new Date(communique.date);
      if (!that.anneeCommunique.includes(date.getFullYear().toString())){
        that.anneeCommunique.unshift(date.getFullYear().toString());
      }
    });
    }, 1000);
  }
  afficheCommunique(communique: PressroomCommunique){
    if (this.anneeChoisi === 'all'){
      return true;
    } else {
      const date = new Date(communique.date);
      return this.anneeChoisi === date.getFullYear().toString();
    }
  }

  getTitle() {
    if (this.anneeChoisi === 'all'){
      return 'Communiqués';
    } else {
      return 'Communiqués envoyé en  ' + this.anneeChoisi;
    }
  }

  getUrlImage(communique: PressroomCommunique) {
    return Environement.BASE_API_URL + '/communique/' + communique.ancienId + '.' + communique.photo;
  }

  getCpLink(communique: PressroomCommunique){
    return Environement.BASE_API_URL + '/communique/' + communique.ancienId + '.pdf';
  }
}
